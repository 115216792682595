<template>
  <page-main
    title="Now we're going to cover languages and soft skills"
    :aside="[
      'Soft skills are also important - be sure to capture that side of yourself here.',
      'If you know a language well enough to use it in a work environment, record it here.'
    ]"
    nextText="Last One: Organizations and Volunteering"
    :buttonTabIndex="nextti"
    eventName="nav"
    @nav="nav('/Organizations')"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Soft Skills"
            titleText="Soft Skills: "
            :optionsList="baseSoftSkills"
            :selected.sync="softSkills"
            tabindex="1"
            autofocus
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-dual-list
            label="Languages:"
            labelOne="Language"
            labelTwo="Proficiency"
            buttonLabel="Add Language"
            :listOneOptions="baseLanguages"
            :listTwoOptions="proficiencyLevels"
            v-model="languages"
            tabindex="2"
            dataNameOne="language"
            dataNameTwo="languageProficiency"
          ></r-dual-list>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as Display from "../utility/display";
import * as uuid from "../utility/guid";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "SoftSkills",
  components: {},
  data: () => ({
    proficiencyLevels: [
      "Basic",
      "Conversant",
      "Proficient",
      "Fluent",
      "Native Speaker"
    ]
  }),
  methods: {
    save() {
      this.buildDisplay();
      this.saveSoftSkills();
      this.saveLanguages();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("softSkill", ["saveSoftSkills"]),
    ...mapActions("language", ["saveLanguages"]),
    buildDisplay() {
      let displaySoftSkills = Display.BuildCommaString("", this.softSkills);

      let displayLanguages = this.languages.filter(l => l.language.length > 0).reduce(
        (p, n) => p + n.language + " (" + n.languageProficiency + "), ",
        ""
      );
      displayLanguages = displayLanguages.substring(
        0,
        displayLanguages.length - 2
      );

      this.softSkillsDisplay = displaySoftSkills;
      this.languagesDisplay = displayLanguages;

      return [displaySoftSkills, displayLanguages];
    },
    validLanguages(current) {
      let setCurrent = new Set();
      this.languages.forEach(l => {
        setCurrent.add(l.language);
      });

      return [current, ...this.baseLanguages.filter(bl => !setCurrent.has(bl))];
    },
    removeLanguage(id) {
      this.languages = this.languages.filter(l => l.id != id);
    },
    appendEmptyLanguage() {
      this.languages.push({
        language: "",
        languageProficiency: "",
        id: uuid.gen()
      });
    },
    listti(listIndex, subListIndex) {
      let ti = 1 + 3 * listIndex + subListIndex; // 3 tabs in each list, 1 tab before list
      return ti.toString();
    }
  },
  mounted() {
    if (this.languages?.length === 0) {
      this.appendEmptyLanguage();
    }
  },
  computed: {
    displayValue: function() {
      return this.buildDisplay();
    },
    ...mapFields("softSkill", [
      "softSkills",
      "softSkillsDisplay",
      "baseSoftSkills"
    ]),
    ...mapFields("language", [
      "languages",
      "languagesDisplay",
      "baseLanguages"
    ]),
    nextti() {
      let ti = this.languages.length * 3 + 3; // tab index for next button
      return ti.toString();
    },
    addLangti() {
      let ti = this.languages.length * 3 + 2; // tab index for the Add language button
      return ti.toString();
    },
    anyLanguages() {
      if (
        this.languages.find(l => !Display.IsNullorWhitespace(l.language)) !==
        undefined
      ) {
        return "L";
      }
      return "";
    }
  }
};
</script>
